<template>
  <v-container
    class="products-list-page"
    fluid>
    <member-header
      :filter="filter"
      @filter-clicked="filterClicked($event)" />
    <v-row class="products-row">
      <v-col cols="12">
        <member-list
          v-model="query"
          :items="members"
          :loading="fetchingMembers"
          @edit="editClicked($event)"
          @change-type="tryToChangeType"
          @send-promo="sendPromotion($event)" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import MemberProvider from '@/resources/MemberProvider'
import MemberHeader from '../components/MembersHeader.vue'
import MemberList from '../components/MembersList.vue'

const MemberService = new MemberProvider()

export default {
  components: {
    MemberHeader,
    MemberList
  },
  data () {
    return {
      fetchingMembers: false,
      members: [],
      filter: {
        type: '',
        birthMonth: '',
        createdIn: '',
        spendingMinPrice: null,
        spendingMaxPrice: null,
        search: '',
        fullSearch: '0'
      },
      query: {
        page: 1,
        limit: 10,
        total: 0,
        pages: 1,
        sortBy: ['createdAt'],
        sortDesc: [true]
      }
    }
  },
  watch: {
    'query.page': {
      handler () {
        this.getMembers()
      },
      deep: true
    }
  },
  async mounted () {
    this.getMembers()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading',
      setMember: 'Member/setMember'
    }),
    async getMembers () {
      try {
        this.fetchingMembers = true
        const spendingMinPrice = !this.filter.spendingMinPrice ? -1 : this.filter.spendingMinPrice
        const spendingMaxPrice = !this.filter.spendingMaxPrice ? -1 : this.filter.spendingMaxPrice
        const search = this.fullSearch === '1'
          ? this.telFormatter(this.filter.search)
          : this.filter.search.replaceAll(/[-\s]/g, '')

        const { data } = await MemberService.getMembersByPaginate({ ...this.query, ...this.filter, spendingMinPrice, spendingMaxPrice, search })
        this.members = data.results
        this.query.total = data.total
        this.query.pages = data.pages
      } catch (error) {
        console.error('error', error)
        this.setSnackbar({
          value: true,
          message: `[GETTING MEMBER INFO]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.fetchingMembers = false
      }
    },
    telFormatter (_tel) {
      let tel = _tel
      if (this.isDigits(tel) && tel[0] !== '+' && tel.length === 10) {
        tel = tel.slice(1, 10)
      }

      return tel
    },
    isDigits (value) {
      // eslint-disable-next-line
      return value == +value
    },
    filterClicked (filter) {
      if (this.query.page === 1) {
        this.filter = filter
        this.getMembers()
      } else {
        this.query.page = 1
        this.filter = filter
      }
    },
    editClicked (member) {
      this.setMember(member)
      this.$router.push({ name: 'EditMember', params: { id: member.id } })
    },
    async tryToChangeType (member, type) {
      this.setModal({
        value: true,
        title: 'Change member type',
        message: 'Do you want to change type of member?',
        confirmText: 'Confirm',
        confirmType: 'success',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.changeTypeMember(member, type)
      })
    },
    async changeTypeMember (member, type) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'UPDATING MEMBER...'
        })

        const { data } = await MemberService.updateMember(member.id, { ...member, type })
        const foundMember = this.members.findIndex((each) => each.id === data.id)

        if (foundMember !== -1) {
          this.members.splice(foundMember, 1, data)
        }
      } catch (error) {
        console.error('error', error)
        this.setSnackbar({
          value: true,
          message: `[UPDATE MEMBER ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.setSnackbar({
          value: true,
          message: `${member.firstName || 'สมาชิก'} ถูกเปลี่ยนเป็น ${type.toUpperCase()} แล้ว`,
          type: 'success'
        })
      }
    },
    async sendPromotion (member) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'SEND PROMOTION CODE...'
        })

        await MemberService.sendPromotionCode(member.tel)

        this.setSnackbar({
          value: true,
          message: 'ส่งโค้ดโปรโมชั่นแล้ว',
          type: 'success'
        })
      } catch (error) {
        console.error('error', error)
        this.setSnackbar({
          value: true,
          message: `[SEND PROMOTION CODE ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    }
  }
}
</script>
